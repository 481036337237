import React from "react"
import { Link } from 'gatsby';
import { Provider } from 'react-redux';

import store from '../store';
import Layout from "../components/layout"
import SEO from "../components/seo";

import '../styles/global.css';

const NotFoundPage = () => (
  <Provider store={store}>
    <Layout>
      <SEO title="404: Not found" />
      <div className="not-found-container">
        <h1>Page Not Found.</h1>
        <Link to="/">Head back to the main page.</Link>
      </div>
    </Layout>
  </Provider>
);

export default NotFoundPage;
